import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function Home() {
	const [txtGoto, setTxtGoto] = useState<string>('');

	const links = [
		[
			{ name: 'nxc', link: 'https://nextcloud.enrhu.be/' },
			{ name: 'files', link: 'https://nextcloud.enrhu.be/apps/files' },
			{ name: 'notes', link: 'https://nextcloud.enrhu.be/apps/notes' },
			{ name: 'photos', link: 'https://nextcloud.enrhu.be/apps/memories' },
		],
		[
			{ name: 'jellyfin', link: 'https://jellyfin.enrhu.be/' },
			{ name: 'element', link: 'https://element.enrhu.be/' },
			{ name: 'git', link: 'https://git.enrhu.be/' },
			{ name: 'calibre', link: 'https://calibre.enrhu.be/' },
		],
		[
			{ name: '/insultron.html', link: 'https://enrhu.be/insultron.html' },
			{ name: 'password/qrcode generator', link: '/createqrcode' },
			{ name: '/sudoku.html', link: 'https://enrhu.be/sudoku.html' },
		],
		[
			//{ name: 'Public chat', link: 'https://nextcloud.enrhu.be/call/cs3sx4on' },
			{ name: 'public notes', link: 'https://nextcloud.enrhu.be/s/publicnotes' },
			{ name: 'jitsi', link: 'https://jitsi.enrhu.be/' },
			{ name: 'file drops', link: 'https://nextcloud.enrhu.be/s/filesdrop' },
		],
	];

	const gotos: { [key: string]: string } = {
		'mvs': 'https://nextcloud.enrhu.be/apps/bookmarks/public/JJHsdoL',
		'todos': 'https://nextcloud.enrhu.be/apps/bookmarks/public/nBPquFY',
		'zik': 'https://nextcloud.enrhu.be/apps/bookmarks/public/sJY30K4',
	}
	function onGotoClicked() {
		const val = txtGoto.trim();
		if (val === '')
			return;
		const goto = gotos[val];
		if (goto !== undefined) {
			window.location.href = goto;
			return;
		}
		if (val.startsWith('http://') || val.startsWith('https://')) {
			window.location.href = val;
			return;
		}
		window.location.href = `/api/${val}`;
	}

	return <>
		{links.map((v, i) =>
			<Container key={i} className="d-flex flex-wrap justify-content-evenly">
				{v.map((w, j) =>
					<a key={j} className="btn btn-primary fs-5 fw-bold m-3 px-3" href={w.link}>{w.name}</a>
				)}
			</Container>
		)}

		<InputGroup className="my-3 px-5">
			<Form.Control
				value={txtGoto}
				onChange={(e) => setTxtGoto(e.target.value)}
				onKeyDown={(e) => { if (e.key === 'Enter') onGotoClicked(); }}
			/>
			<Button className="input-group-text"
				onClick={() => onGotoClicked()}
			>
				Goto
			</Button>
		</InputGroup>
	</>
}

export default Home;
